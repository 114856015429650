.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.container { 
  max-width: 700px !important;
}

div.main {
  width: 960px;
  margin: auto;
}

div.buttons {
  width: 100%;
  margin: auto;
  text-align: center;
}

div.logo {
  width: 100%;
  background: #ffffff url('../images/logo_dropbox.png') top center no-repeat;
  height: 170px;
}

div.logo-send {
  width: 100%;
  background: #ffffff url('../images/logo_dropbox_send.png') top center no-repeat;
  height: 100px;
}

div.logo-get {
  width: 100%;
  background: #ffffff url('../images/logo_dropbox_get.png') top center no-repeat;
  height: 100px;
}

div.send {
  border: 1px dashed #d0d4d9; 
  background-color: #f0f4f9;
  padding: 15px;
  text-align: left;  
  font-size: 14px;
  width: 100%;
}

div.getcode {
  width: 534px;
  height: 175px;
  margin: auto;
  background: #ffffff url('../images/getcode_bg.png') top center no-repeat;
  text-align: center;
  padding-top: 70px;
  padding-left: 30px;
}

div.email {
  border: 1px dashed #d9d4d0; 
  background-color: #f9f4f0;
  padding: 10px;
  text-align: left;  
  font-size: 14px;
}

@media (min-width: 768px) {
  .form-group.row > .col-form-label {
    text-align: right;
  }
}
